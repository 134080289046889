.picturemenu {
    border: 2px dotted black;;
    width: 74vw;
    padding: 50px;
    top: fixed;
    top: 105%;
    border-radius: 6px;
    background-color:whitesmoke;
}
.lablesetting{
  display: flex
}
.requird{
  text-align: right;
  max-width: 80%;
}
.leftlable{
flex: 1;
}
.reqcolor{
  color: red;
  font-weight: 500;
}
.top {
margin-bottom: 40px;
display: flex;
justify-content: center;
align-items: center;
font: 1.7em Georgia, serif ;
font-weight: bold;
color: rgb(2, 13, 111);
}

.secondheading {
  display: flex;
  font-family: Georgia, serif;
  font: 1.2em Georgia, serif ;
  color: rgb(2, 13, 111);
}
.headDivin {
    margin: auto;
    margin-top: 20px;
    width: 80vw;
    padding: 50px;
    top: fixed;
    top: 15%;
    left: 10%;
    border-radius: 10px;
    background-color:white;
  }
 .progbar{
  display: flex;
  flex-direction: column;
 }
 .tex{
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .bar{
  display: flex;
  justify-content: center;
  align-items: center;
 }
  .input {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical
    
  }
  .input-phone-number input{

    width: 100%; /* Full width */
    padding: 8px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    resize: vertical
    
}
  .inputt {
    width: 100%;
    height: 150px;
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical;
    white-space: initial;
    
    
  }
  .input:focus {
    border: 3px solid #555;
  }
  
  .updatedbutton {
    margin-top: 30px;
    width: 100%;
    height: 48px;
    /* border-color: transparent; */
    background-color: #002f34;
    color: whitesmoke;
    font-weight: 900;
  }
  .updatedbutton:hover {
    background-color: white;
    color: #002f34;
    border: 2px solid #002f34;
  }
  .headDivin label{
    font-weight: bold;
  }
  .pricecurrncy{
    display: flex;
    flex-direction: row;
  }
  .pr{
    margin-right: 15px;
    flex: 50%;
  }
  .curr{
    margin-left: 15px;
    flex: 50%;
  }
  .weiuni{
    display: flex;
    flex-direction: row;
  }

  .wei{
    margin-right: 15px;
    flex: 50%;
  }
  .uni{
    margin-left: 15px;
    flex: 50%;
  }
  .mobcontr{
    display: flex;
    flex-direction: row; 
  }
  .mob{
    margin-right: 15px;
    flex: 50%;
  }
  .contr{
    margin-left: 15px;
    flex: 50%;
  }
  @media only screen and (max-width: 800px){
    .headDivin {
      margin-top: 10px;
      width: 100vw;
      padding: 50px;
      top: fixed;
      top: 15%;
      left: 10%;
      border-radius: 10px;
      background-color:white;
    }
    .weiuni{
      flex-wrap: wrap; 
    }
    .pricecurrncy{
      flex-wrap: wrap;
    }
    .mobcontr{
      flex-wrap: wrap;
    }
    .wei{
      flex: 100%;
      margin-right: 0px;
    }
    .uni{
      flex: 100%;
      margin-left: 0px;
    }
    .pr{
      flex: 100%;
      margin-right: 0px;
    }
    .curr{
      flex:100%;
      margin-left: 0px;
    }
    .mobcontr{
      flex-wrap: wrap;
      margin-left: 0px;
    }
    .mob {
      flex: 100%;
      margin-right: 0px;
    }
    .contr {
      flex: 100%;
      margin-left: 0px;
    }
    .reqcolor{
      color: red;
      font-weight: 400;
    }

    .top h2{
      margin-bottom: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: .7em Georgia, serif ;
      font-weight: 400;
      color: rgb(2, 13, 111);
      }

    .secondheading h3{
      font-weight: 400;
      font: 1em Georgia, serif;
      color: rgb(2, 13, 111);
    }
    .headDivin label{
      font-weight: 500;
    }
  }
 


  

  .selecting label{
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px dotted black;
      border-radius: 20px;
      width: 10rem;
      height: 5rem;
      cursor: pointer;
      font-size: large;
      background-color: green;
      
    
  }

  
  .selecting label span {
    font-weight: lighter;
    font-size: small;
    padding-top: 0.5rem;
  }
  
  .selecting input {
    display: none;
  }
  
  .selecting img {
    padding: 0;
    margin: 0;
  }
  
  .selecting .images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    
  }
  
  .selecting .image {
    margin: 1rem 0.5rem;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border: 6px solid white;
    border-radius: 6px;
    
  }
  
  .selecting .image button {
    position: absolute;
    border-radius: 3px;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border: none;
    color: white;
    background-color: lightcoral;
  }
  
  .selecting .image button:hover {
    background-color: red;
  }
  
  .selecting .image p {
    padding: 0 0.5rem;
    margin: 0;
  }
  
  .selecting .upload-btn {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    border: none;
    border-radius: 20px;
    width: 10rem;
    height: 3rem;
    color: white;
    background-color: rgb(30, 119, 30);
  }
  
  .selecting label:hover,
  .upload-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
  
  .selecting .error {
    text-align: center;
  }
  
  .selecting .error span {
    color: red;
  }