.postdetailing {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 4em;
  width: 80vw;
  padding: 50px;
  border-radius: 6px;
  background-color:white;
}

/* .leftarrow{
  position: absolute;
  left: 19%;
  transform: translate(0, -30%);
  font-size: 55px;
  color: #fff;
  cursor: pointer;
}
.rightarrow {
  position: absolute;
  right: 33%;
  transform: translate(0, -30%);
  font-size: 55px;
  color: #fff;
  cursor: pointer;
} */
.showingImageDiv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftarrow, .rightarrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 55px;
  color: #fff;
  cursor: pointer;
}

.leftarrow {
  left: 5%;
}

.rightarrow {
  right: 5%;
}
.maina {
  display:flex; flex-direction:row;
}
.location {
  display:flex; flex-direction:row;
}

.productandsellerprofile {
  display:flex; flex-direction:row;
}
.right {
  display:flex; flex-direction:row;
  
}
.avatarnameofseller{
  display:flex; flex-direction:row;
}

.countryname {
  font-size: 16px;
  margin-left: 1%;
}
.createdat {
  margin-left: auto;
}
.addressdetail {
  margin-top: 1em;
}
.maina .flex_row {
  margin-left: 4%;
  margin-bottom: 4%;
  
}
.maina .flex_row .thumbnail img {
  height: 100px;
  width: 150PX;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.maina .flex_row .thumbnail {
  height: 29%;
}
.maina .showingImageDiv {
    margin-left: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 520px;
    width: 780PX;
    background-color: #002f34;
    
}
.maina .showingImageDiv img {
  max-width: 100%;
  max-height: 100%;
  /* min-width: 100%; */
   
}


.postdetailing .rightPhase {
  padding: 16px;
  margin-top: 3em;
  flex:60%
}

.postdetailing .rightPhase .productDetails .productprice {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b8b6b6;
  margin-bottom: 8px;
  color: #000000;
  line-height: 32px;
  margin-bottom: 20px;
}

.postdetailing .rightPhase .productDetails .catagorydetail{
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b8b6b6;
  margin-bottom: 8px;
  color: #000000;
  line-height: 32px;
  margin-bottom: 20px;
}
.postdetailing .rightPhase .productDetails .weightdetail{
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b8b6b6;
  margin-bottom: 8px;
  color: #000000;
  line-height: 32px;
  margin-bottom: 20px;
}
.postdetailing .rightPhase .productDetails .addressdetail{
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b8b6b6;
  margin-bottom: 8px;
  color: #000000;
  line-height: 32px;
  margin-bottom: 20px;
}

.leftside{
  font-weight: 500;
  color: #3b3b3b;
  font-size: 17px;
  flex: 1;
}

.rightside{
font-weight: 550;
font-size: 18px;
max-width: 60%;
text-align: right;
}

.postdetailing .right .rightPhase .productDetails .postname{
  font-weight: 700;
  margin-bottom: 10px;
  color: #000000;
  font-size: 24px;
  line-height: 40px;
  margin-bottom: 20px;
  word-wrap: break-word
}

.postdetailing .rightPhase .productDetails {
  margin-left: 7.5%;
  padding: 6px;
  margin-bottom: 2em;
  width: 100%; 
}
 .addressdetail{
  word-wrap: break-word;
}

.sellerr {
  margin: auto;
}
 .sellericon{
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  color: #ffffff;
 }
 .nameofseller{
  margin-top: 20px;
  margin-left: 15px;
  text-align: center;
  font-size: 17px;
  font-weight: bolder;
  overflow: hidden;
 }
 .relatename{
  margin-top: 20px;
  margin-left: 15px;
  font-size: 23px;
  font-weight: bolder;
  
 }
/* seller box setting  */
 .sellerbox{
  margin-top: 5%;
  margin-left: 25%;
  max-width: 150px;
  border: 1px solid #DFDFDF;
  width: 150px;
  height: 35px;
  display: inline-block;
  box-shadow: 0 4px 4px 0 rgba(207, 207, 207, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
  background-color:rgb(9, 40, 56); ;
  border-radius: 10px;
 }
 .sellerdetailbox {
  margin-top: 65px;
  margin-left: 80px; 
  /* margin: auto; */
  max-width: 300px;
  border: 1px solid #DFDFDF;
  width: 360px;
  height: 230px;
  display: inline-block;
  box-shadow: 0 4px 4px 0 rgba(207, 207, 207, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
  background-color: #ffffff;
  border-radius: 10px;
}
.avatar {
  margin-left: 20px;
  margin-top: 15px;
}
.shownumberbutton {
  margin-left: 25px;
  margin-top: 24px;
  box-shadow: 0 4px 4px 0 rgba(207, 207, 207, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
  background-color: rgb(9, 40, 56); 
  border: 1px solid #dfdfdf;
  color: white;
  cursor: pointer; 
  width: 250px;
  height: 45px;
  padding-top: 8px;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}

.relatedfresh-recomendation-cards{
  white-space: nowrap; 
}
.recommendationsrelated {
 margin-left: 10px;
 overflow: auto;
 flex-direction: row;

}
@media only screen and (max-width: 1080px){
  .right {
    display:flex; flex-direction:column;
  }
  .postdetailing .rightPhase .productDetails {
    margin-left: 1%;
    padding: 6px;
    margin-bottom: 2em;
    width: 100%; 
  }
  .sellerdetailbox {
    margin-top: 10px;
    margin-left: 10%;
    max-width: 300px;
    border: 1px solid #DFDFDF;
    width: 360px;
    height: 230px;
    box-shadow: 0 4px 4px 0 rgba(207, 207, 207, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
    background-color: #ffffff;
    border-radius: 10px;
  }
  .maina .flex_row .thumbnail img {
    height: 70px;
    width: 100PX;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 10px 10px 10px 10px;
  }
  
}
@media only screen and (max-width: 800px){
  .maina {
    display:flex;
    flex-direction:column;
    margin: 0%;
  }

  /* .maina .flex_row {
    margin-left: 0%;
    margin-bottom: 0%;
    
  } */
  .maina .showingImageDiv img {
    max-width: 100%;
    max-height: 100%;
    min-width: 0%;

  }
  .maina .showingImageDiv {
    margin: 0%;
    margin-left: 0%;
    height: 250px;
    width: 100%;
    background-color: #002f34;
    
}
.postdetailing .showingImage .maina .flex_row {
  margin-top: 40px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
}

.maina .flex_row .thumbnail {
    width: 30%;
    height: 0%;
}
.maina .flex_row .thumbnail img {
  height: 70px;
  width: 80PX;
  justify-content: center;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
}
.postdetailing {
  margin: 0%;
  margin-top: 20px;
  padding-top: 0em;
  padding :0px;
  width: 100vw;
  background-color:white;
}
.postdetailing .right .rightPhase {
  padding: 16px;
  margin-top: 1em;
  flex:60%
}
.postdetailing .showingImage{
  width: 100vw; 
}

.right {
  margin-top: 7%;
  display:flex;
  flex-direction:column;
}

.sellerdetailbox {
  margin-top: 10px;
  margin-left: 8%;
  max-width: 300px;
  border: 1px solid #DFDFDF;
  width: 360px;
  height: 230px;
  box-shadow: 0 4px 4px 0 rgba(207, 207, 207, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
  background-color: #ffffff;
  border-radius: 10px;
}
.postdetailing .rightPhase .productDetails {
  margin-top: 2%;
  margin-left: 1%;
  margin-right: 2%;
  padding: 6px;
  border-radius: 6px;
  margin-bottom: 2em;
  width: 90vw;
  overflow: auto;
}

.leftside{
  font-weight: 500;
  color: #3b3b3b;
  font-size: 14px;
  flex: 1;
}
.rightside{
  font-weight: 550;
  font-size: 14px;
  max-width: 60%;
  text-align: right;
  }
/* .productDetails .postname{
    font-weight: 700;
    margin-bottom: 10px;
    color: #000000;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 20px;
    word-wrap: break-word
  } */

.leftarrow{
  position: absolute;
  left: 3%;
  transform: translate(0, -30%);
  font-size: 55px;
  color: #fff;
  cursor: pointer;
}
.rightarrow {
  position: absolute;
  right: 3%;
  transform: translate(0, -30%);
  font-size: 55px;
  color: #fff;
  cursor: pointer;
}
}
