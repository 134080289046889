
.viewMore{
  margin: auto;
  margin-top: 20px;
  width: 85vw;
  margin-bottom: 70px;
}

.productHeading {
  background: linear-gradient(45deg, #004d00, #006400);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.loadingContainer {
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height to center vertically */
}

.allpostdone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
}

.viewMore .cardes {
  background-color: white;
}
.postescards {
  white-space: nowrap; 
}


@media (max-width: 768px) {
  .productHeading {
    font-size: 1.5rem; /* Tablet */
  }
}

@media (max-width: 480px) {
  .productHeading {
    font-size: 1.2rem; /* Phone */
  }
}

.viewMore .viewMoreButton {
  padding: 10px 20px; /* Adjust padding for better spacing */
  border: 2px solid #006400; /* Green border */
  color: #006400; /* Green text */
  background-color: transparent; /* Transparent background */
  font-weight: bold; /* Bold text */
  border-radius: 10px; /* Rounded corners */
  transition: all 0.3s ease; /* Initial shadow */
}

/* Hover Effect for View More Button */
.viewMore .viewMoreButton:hover {
  background-color: #006400; /* Dark green background on hover */
  color: white; /* White text on hover */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
  transform: translateY(-2px); /* Slight lift effect on hover */
}
