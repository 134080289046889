.filter-posts{
    margin-top: 10px;
    padding-bottom: 1em;
    background-color: white;
}

.filterpost-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;

}
.allpostbox{
  margin: auto;
  padding-top: 1em;
  width: 80vw;
  
}

.cardfilter{
  /* display:flex;
  justify-content: space-around;
  flex-wrap: wrap; */
  margin: 1px ;
  white-space: nowrap; 
    

}