.loginParentDiv {
    border: 1px solid black;
    width: max-content;
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
  }
  
  .loginParentDiv .input {
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    outline-color: transparent;
    
  }
  
  .loginParentDiv button {
    width: 100%;
    height: 48px;
    /* border-color: transparent; */
    background-color: #002f34;
    color: whitesmoke;
    font-weight: 900;
  }
  .loginParentDiv button:hover {
    background-color: white;
    color: #002f34;
    border: 2px solid #002f34;
  }
  
  .loginParentDiv a {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .loginParentDiv img{
    position: top;
    margin-bottom: 3em;
  }
  
  