.tagent{
    margin-top: 10px;
    padding-bottom: 1em;
    background-color: rgb(255, 255, 255);
}

.containring{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: center;

}
.bocing{
  margin: auto;
  padding-top: 1em;
  width: 80vw;
  
}

.filteration{
  /* display:flex;
  justify-content: space-around;
  flex-wrap: wrap; */
  margin: 1px ;
  white-space: nowrap; 
    

}