.startprivacy {
    margin: auto;
    
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80vw;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 6px;
    background-color: #fdfdfd; 
}

.basicfunction{
    margin-Bottom: '8.0pt';
    font-size: '11.0pt';
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.normalfunction{
    font-family: Arial, sans-serif;
}

.startterm{
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80vw;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 6px;
    background-color: #fdfdfd; 
}