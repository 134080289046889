.card {
    margin-top: 15px;
    margin-right: 10px;
    margin-bottom: 15px;
    max-width: 300px;
    width: 280px;
    height: 360px;
    display: inline-block;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 4px 4px 0 rgba(207, 207, 207, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
  }
  
  .card .favorite {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .card .image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card .image img {
    height: 220px;
    width: 280PX;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    text-align: center;
    border-radius: 10px 10px 0px 0px;
  }
  .card .content .rate {
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    overflow: hidden;
    color: green;
    margin-right: 10px;
  }
  
  .card .content .name {
    margin-top: 5px;
    margin-left: 10px;
    font-size: larger;
    overflow: hidden;
    margin-right: 10px;
    font-weight: bold;

  }
  .card .content .date {
    margin-top: 3px;
    margin-right: 15px;
    display: flex;
    justify-content: flex-end;
    font-size: small;
  }
  .card .content .category {
    margin-top: 10px;
    margin-left: 10px;
    flex: 60%;
    overflow: hidden;
  }
  /* .card .content .addresscountry .address{
    margin-top: 10px;
    margin-left: 10px;
    flex: 40%;
    overflow: hidden;
  } */
  /* .addresscountry{
    display: flex;
    flex-direction: row;
  } */
  @media only screen and (max-width: 800px) {
    .card {
      margin-top: 10px;
      margin-right: 10px;
      margin-bottom: 5px;
      max-width: 180px;
      width: 280px;
      height: 200px;
      display: inline-block;
      cursor: pointer;
      border-radius: 10px;
      box-shadow: 0 4px 4px 0 rgba(207, 207, 207, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
    }

    .card .image img {
      height: 120px;
      width: 180PX;
      max-width: 100%;
      max-height: 100%;
      min-height: 100%;
      text-align: center;
      border-radius: 10px 10px 0px 0px;
    }
    .card .content .rate {
      margin-left: 10px;
      margin-top: 8px;
      font: .8em sans-serif;
      overflow: hidden;
      color: green;
    }
    .card .content .category {
      margin-top: 8px;
      margin-left: 10px;
      font: .8em sans-serif;
      overflow: hidden;
    }
    /* .card .content .addresscountry .address{
      margin-top: 8px;
      font: .8em sans-serif;
      overflow: hidden;
    } */
    .card .content .name {
      margin-top: 2px;
      margin-left: 10px;
      font: .9em sans-serif;
      overflow: hidden;
      margin-right: 10px;
      font-weight: bold;
  
    }
    .card .content .date {
      display: flex;
      justify-content: flex-end;
      font: .6em sans-serif;
      overflow: hidden;
      
    }
    /* .card .content .categodate {
      display: flex;
      
    } */

    
  
  }