.mainstart {
  padding: 10px;
  background-color: rgb(255, 255, 255);
  width: 100vw;
  z-index: 999;
  box-shadow: 0 4px 4px 0 rgba(86, 86, 86, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
}

.mainChildDiv {
  display: flex;
  text-align: center;
  
}
.brandName {
  padding-top: 7px;
  text-align: center;
}
.log0height {
 height: 40px;
}

.searbarbutton{
  flex: 70%;
  flex-direction:row;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
 }
 .menureitems{
  flex-direction:row;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
 }

.loginPage{
  flex: 10%;
  display: inline-block;
  justify-content: space-between;
}
.loginPage .signin-button {
  background-color: white;
  color: black;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Poppins';
  padding-top: 15px;
  border-radius: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}


.sellbuttondiv .sellbutton{
  height: 50;
  white-space: nowrap;
  flex: 10%;
  margin-right: 40px;
  margin-top: 5px;
  display: inline-block;
  background-color: #ca2d2d;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-style: normal;
  cursor: pointer;
  border-radius: 16px;
}
.sellbuttondiv .sellbutton:hover {
 
  display: inline-block;
  background-color: #56ab2f;
  border: none;
  color: white;
 
}

.span {
  text-decoration: none;
  font-size: 16px;
  white-space: normal;
  text-overflow: ellipsis;
  font-weight: bolder;
  margin-right: 5px;
  cursor: pointer;
}

.loginPage hr {
  margin: 0;
  background-color: black;
  height: 2px;
}

.loginPage a{
  text-decoration: none;
}

.logout-span{
  cursor: pointer;
}

 
 .dataResult-header  :hover {
   background-color: #ebeeef;
 }
 
 .searbarbutton .searchbar{ 
  width: 30vw;
  height: 38px;
  align-items: center;
  padding: 0 8px;
  border: 1px solid #002f34;
  border-radius: 4px ;
  background-color: white;
 
 }

 .searbarbutton .btoon {
   height: 38px;
   background-color: rgb(255, 255, 255);
   border-radius: 4px;
   border: 1px solid #000000;
   align-items: center;
 }

 .linkbuttons{
  font-weight: 500;
  color: rgba(5, 1, 35, 0.784);
  cursor: pointer;
 }
 .mainChildDiv .linkbuttons:hover {
  color:green;
 }
 @media only screen and (max-width: 1075px){
  
  .brandName {
    padding-top: 10px;
    text-align: center;
  }
  .oplastic ,.ometal,.ocopper,.osteel,.oaluminum,.oother{
    display: none;
   }

 }
@media only screen and (max-width: 800px) {
  .mainstart {
    padding: 10px;
    background-color: rgb(255, 255, 255);
    width: 100vw;
    z-index: 999;
    box-shadow: 0 4px 4px 0 rgba(86, 86, 86, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
  }

  .mainChildDiv {
    position: relative;
    flex-wrap: wrap;
    text-align: center;
  }
 
  .brandName {
    padding-top: 10px;
    text-align: center;
  }
  .searbarbutton {
    justify-content: space-evenly;
    align-items: center;
    padding-top: 10px;
    flex: 100%;
    flex-direction:row;
   }
   .oplastic ,.ometal,.ocopper,.osteel,.oaluminum,.oother{
    display: none;
   }

   .loginPage{
    padding-top: 5px;
    position: absolute;
    margin-left: 40%;
    text-align: center;
    display: inline-block;
  }
  .sellbuttondiv{
    position: absolute;
    padding-top: 0px;
    margin-left: 70%;
  }
  .sellbuttondiv .sellbutton{
    display: inline-block;
    background-color: #ca2d2d;
    border: none;
    color: white;
    padding: 10px 10px;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-style: normal;
    cursor: pointer;
    border-radius: 10px;
  }
  .searbarbutton .searchbar {
  
    width: 70vw;
    height: 38px;
    align-items: center;
    padding: 0 8px;
    border: 1px solid #002f34;
    border-radius: 4px;
    background-color: rgb(253, 253, 253);
    
   }

}
@media only screen and (max-width: 430px){
  
  .log0height {
  
    height: 28px;
   }
   .brandName {
    padding-top: 20px;
    text-align: center;
    margin-left: 10px;
    
  }
  .sellbuttondiv .sellbuttondiv{
    position: absolute;
    padding-top: 0px;
    margin-left: 65%;
  }
  .loginPage{
    padding-top: 5px;
    position: absolute;
    margin-left: 40%;
    text-align: center;
    display: inline-block;
  }

}

