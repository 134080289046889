.pointers {
    margin-top: 10px;
    margin-bottom: 5px;
    max-width: 80vw;
    width: 80vw;
    height: 125px;
    display: inline-block;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(207, 207, 207, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
  }
  .content{
    flex: 70;
  }
  .pointers .mainetering{
    display: flex;
    flex-direction: row;
  }
  
 .pointers .yes{
  display: flex;
 }
  
  .pointers .mainetering .image {
    flex:10
  }
  
  .pointers .image img {
    height: 125px;
    width: 150PX;
    border-radius: 5px 0px 0px 5px;
    max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    
    
  }
  .pointers .content .rate {
    margin-top: 5px;
    margin-left: 10px;
    width: 50vw;
    font-weight: bold;
    overflow: hidden;
    color: green;
    margin-right: 10px;
  }
  
  .pointers .content .name {
    width: 30vw;
    margin-top: 5px;
    margin-left: 10px;
    font-size: larger;
    overflow: hidden;
    margin-right: 10px;
    font-weight: bold;

  }
  .pointers .content .date {
    text-align: right;
    margin-top: 3px;
    margin-right: 15px;
    
    width: 60%;
    font-size: small;
  }
  .pointers .content .category {
    margin-top: 5px;
    margin-left: 10px;
    flex: 60%;
    overflow: hidden;
  }
  /* .pointers .content .addresscountry .address{
    margin-top: 10px;
    margin-left: 10px;
    flex: 40%;
    overflow: hidden;
  } */
  /* .addresscountry{
    display: flex;
    flex-direction: row;
  } */
  @media only screen and (max-width: 800px) {
    .pointers {
      margin-top: 10px;
      margin-bottom: 5px;
      max-width: 100vw;
      width: 100vw;
      height: 100px;
      display: inline-block;
      cursor: pointer;
      box-shadow: 0 4px 4px 0 rgba(207, 207, 207, 0.2), 0 6px 20px 0 rgba(39, 39, 39, 0.19);
    }
    .pointers .mainetering .image {
      flex:30
    }
    .pointers .image img {
      height: 100px;
      width: 150PX;
      max-width: 100%;
      
      max-height: 100%;
      min-height: 100%;
    }
    .pointers .content .rate {
      margin-left: 10px;
      margin-top: 10px;
      font: .8em sans-serif;
      overflow: hidden;
      color: green;
    }
    .pointers .content .category {
      margin-top: 8px;
      margin-left: 10px;
      font: .8em sans-serif;
      overflow: hidden;
    }
    /* .pointers .content .addresscountry .address{
      margin-top: 8px;
      font: .8em sans-serif;
      overflow: hidden;
    } */
    .pointers .content .name {
      margin-top: 6px;
      margin-left: 10px;
      font: .9em sans-serif;
      overflow: hidden;
      margin-right: 10px;
      font-weight: bold;
  
    }
    .pointers .content .date {
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
      font: .6em sans-serif;
      overflow: hidden;
      
    }
    /* .pointers .content .categodate {
      display: flex;
      
    } */

    
  
  }