.fotterstart {
    padding:30px 0;
    color:#f0f9ff;
    background-color:#282d32;
  }
  
  .fotterstart h3 {
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:16px;
  }
  
  .fotterstart ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:14px;
    margin-bottom:0;
  }
  
  .fotterstart ul a {
    color:inherit;
    text-decoration:none;
    opacity:0.6;
  }
  
  .fotterstart ul a:hover {
    opacity:1;
  }
  
 
  .fotterstart .item.text {
    margin-bottom:36px;
  }
  .row .linkbuttons{
    font-weight: 500;
    color: rgba(238, 238, 238, 0.784);
    cursor: pointer;
   }
   .row .linkbuttons:hover {
    
    color: green; /* Change background color to green on hover */
    cursor: pointer; /* Hand cursor on hover */
  }
  
  @media (max-width:767px) {
    .fotterstart .item.text {
      margin-bottom:0;
    }
    .container .row {
      text-align: center;
    }
   .abouttop{
    margin-top: 20px;
   }
    .fotterstart .item.social > a {
        font-size:25px;
        width:36px;
        height:36px;
        line-height:36px;
        display:inline-block;
        padding: auto;
        border-radius:50%;
        box-shadow:0 0 0 1px rgba(255,255,255,0.4);
        margin:0 8px;
        color:#fff;
        opacity:0.75;
      }
  }
  
  .fotterstart .item.text p {
    opacity:0.6;
    margin-bottom:0;
  }
  
  .fotterstart .item.social {
    text-align:center;
  }
  
  @media (max-width:991px) {
    .fotterstart .item.social {
      text-align:center;
      margin-top:20px;
    }
  }
  
  .fotterstart .item.social > a {
    font-size:25px;
    width:36px;
    height:36px;
    line-height:30px;
    display:inline-block;
    padding: auto;
    border-radius:50%;
    box-shadow:0 0 0 1px rgba(255,255,255,0.4);
    margin:0 8px;
    color:#fff;
    opacity:0.75;
  }
  
  .fotterstart .item.social > a:hover {
    opacity:1;
  }
  
  .fotterstart .copyright {
    text-align:center;
    padding-top:6px;
    opacity:0.3;
    font-size:13px;
    margin-bottom:0;
  }
  .privacypolices{
    padding-top: 20px;
    text-align: center;
  }